import React from 'react';
import PropTypes from 'prop-types';

const Feedback = ({
  id = '',
  fill = '#fff',
  className = '',
  viewBox = '0 0 24 24',
  width = '100%',
  height = '100%',
}) => (
  <svg
    id={id}
    viewBox={viewBox}
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z" fill={fill} />
  </svg>
);

Feedback.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  fill: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Feedback.defaultProps = {
  fill: '#fff',
  viewBox: '0 0 24 24',
  width: '100%',
  height: '100%',
};

export default Feedback;
