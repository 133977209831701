import React, { useState, useEffect, useRef } from 'react';
import {
  AzureAD, AuthenticationState, LoginType, MsalAuthProvider,
} from 'react-aad-msal';
import { Logger, LogLevel } from 'msal';
import Entries from '../components/entries';
import EntryForm from '../components/entryform';
import config from '../global/config';
import Feedback from '../icons/feedback';
import Logo from '../icons/logo';
import Context from '../utils/context';
import basicReduxStore from './reduxStore';
// import store from '../redux/store';

const Main = () => {
  const useComponentDidMount = func => useEffect(func, []);
  const useComponentWillMount = (func) => {
    const willMount = useRef(true);
    if (willMount.current) {
      func();
    }
    useComponentDidMount(() => {
      willMount.current = false;
    });
  };
  // useComponentDidMount(() => console.log('didMount'));
  useComponentWillMount(() => {
    const url = config.healthUri();
    const opts = {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': config.apiHost(),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const request = async () => {
      try {
        const res = await fetch(url, opts);
        if (res && res.status === 200) {
          Object.assign(config.principal, { mock: false });
        }
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
      }
    };
    request();
  });
  const [valueGlobal, setValueGlobal] = useState(true);
  const incrementValueGlobal = () => {
    setValueGlobal(true);
  };
  const decrementValueGlobal = () => {
    setValueGlobal(false);
  };
  const loginFunction = (loginfun) => {
    setInterval(() => {
      loginfun();
    }, 5000);
  };
  // const { accountInfo } = this.state;
  return (
    <div>
      <AzureAD
        provider={new MsalAuthProvider(
          {
            auth: {
              authority:
        'https://login.microsoftonline.com/4b0911a0-929b-4715-944b-c03745165b3a',
              clientId: '03197a41-5cfd-4f9b-8001-1d3c197c22b4',
              redirectUri: `${window.location.origin}/admin/`,
              postLogoutRedirectUri: `${window.location.origin}/admin/`,
              validateAuthority: true,

              // After being redirected to the "redirectUri" page, should user
              // be redirected back to the Url where their login originated from?
              navigateToLoginRequestUrl: false,
            },
            // Enable logging of MSAL events for easier troubleshooting.
            // This should be disabled in production builds.
            system: {
              logger: new Logger(
                () => {
                },
                {
                  level: LogLevel.Verbose,
                  piiLoggingEnabled: false,
                },
              ),
            },
            cache: {
              cacheLocation: 'sessionStorage',
              storeAuthStateInCookie: false,
            },
          },
          {
            scopes: ['openid'],
          },
          {
            loginType: LoginType.Redirect,
            tokenRefreshUri: `${window.location.origin}/auth.html`,
          },
        )}
        reduxStore={basicReduxStore}
      >
        {({
          login, accountInfo, authenticationState,
        }) => (
          <Context.Provider
            value={{
              // global state with useState
              valueGlobalState: valueGlobal,
              addGlobalValue: () => incrementValueGlobal(),
              decGlobalValue: () => decrementValueGlobal(),
            }}
          >
            {authenticationState === AuthenticationState.Unauthenticated && (
            <div>
              {/* {console.log(authenticationState, window.location)} */}
              {loginFunction(login)}
            </div>
            )}
            {authenticationState === AuthenticationState.Authenticated && (
            <div className="luci-site-page-shell">
              <header className="luci-site-page-shell__header">
                <div className="luci-site-page-shell__header-inner">
                  <div className="luci-site-top-hat">
                    <div className="luci-site-top-hat__utils">
                      <span className="luci-link--small luci-link--on-dark luci-link__text">
                        {`| ${accountInfo.account.name} |`}
                      </span>
                      <span className="luci-link--small luci-link--on-dark luci-link__text">
                            &nbsp;
                      </span>
                      <a
                        className="luci-link luci-link--small luci-link--on-dark"
                        href="mailto:ng-tsi@netapp.com"
                      >
                        <Feedback
                          id="luci-site-feedback"
                          className="luci-icon luci-link__icon luci-link__icon--left"
                          aria-hidden="true"
                        />
                        <span className="luci-link__text">
                          Contact DevExp
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="luci-site-property-bar">
                    <Logo
                      id="logo"
                      className="luci-site-property-bar__logo"
                    />
                    <span className="luci-site-property-bar__title">
                      <a
                        href="/"
                        className="luci-site-property-bar__home-link"
                      >
                        <h1 className="luci-site-property-bar__name">
                          NetApp Tiny URL Tool
                        </h1>
                      </a>
                    </span>
                  </div>
                </div>
              </header>
              <div className="luci-site-page-shell__body">
                <div className="luci-site-page-shell__content">
                  <EntryForm />
                  <div />
                  <Entries />
                </div>
                <div className="luci-site-page-shell__site-navigation" />
              </div>
            </div>
            )}
          </Context.Provider>
        )}
      </AzureAD>
    </div>
  );
};

export default Main;
