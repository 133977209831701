import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({
  id = '',
  className = '',
  viewBox = '0 0 177.572 147.978',
  width = '100%',
  height = '100%',
}) => (
  <svg
    id={id}
    viewBox={viewBox}
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M0 0v147.978h69.062V59.187h39.462v88.791h69.048V0z" />
  </svg>
);

Logo.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Logo.defaultProps = {
  viewBox: '0 0 177.572 147.978',
  width: '100%',
  height: '100%',
};

export default Logo;
