import { LIST_TINY, NEW_TINY, DEL_TINY } from '../global/actions';
import config from '../global/config';

export // console.log(entry);
const postOptions = entry => ({
  method: 'POST',
  headers: {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': config.apiHost(),
    Authorization: `Bearer ${sessionStorage.getItem('msal.03197a41-5cfd-4f9b-8001-1d3c197c22b4.idtoken')}`,
  },
  body: JSON.stringify(entry),
});


export const getOptions = () => ({
  method: 'GET',
  headers: {
    'Access-Control-Allow-Origin': config.apiHost(),
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('msal.03197a41-5cfd-4f9b-8001-1d3c197c22b4.idtoken')}`,
  },
});

export const delOptions = () => ({
  method: 'DELETE',
  headers: {
    'Access-Control-Allow-Origin': config.apiHost(),
    Authorization: `Bearer ${sessionStorage.getItem('msal.03197a41-5cfd-4f9b-8001-1d3c197c22b4.idtoken')}`,
  },
});

// function mockListDispatch(dispatch) {
//   return {
//     type: LIST_TINY,
//     payload: config.mockStore().data.list,
//   };
// }

export const listDispatch = (res, json) => {
  if (res.status === 200) {
    return { type: LIST_TINY, payload: json.entries };
  }
  if (res.status === 204) {
    return { type: LIST_TINY, payload: [] };
  }
  return false;
};
export const newTinyDispatch = (res) => {
  if (res.status === 201) {
    return { type: NEW_TINY, payload: res.status };
  }
  return false;
};

export const delTinyDispatch = (res) => {
  if (res.status === 202) {
    return { type: DEL_TINY, payload: res.status };
  }
  return false;
};

export const listTiny = () => {
  if (config.mock()) {
    return {
      type: LIST_TINY,
      payload: config.mockStore().data.list,
    };
  }
  return false;
};

export const newTiny = (entry) => {
  if (config.mock()) {
    const ent = { ...entry };
    ent.counter = 0;
    ent.last = 0;
    ent.expire = -1;
    config.mockStore().data.list.push(ent);
    return {
      type: NEW_TINY,
      payload: 202, // HTTP_ACCEPTED
    };
  }
  return false;
};

export const delTiny = (entry) => {
  if (config.mock()) {
    config.mockStore().data.list.forEach((item, index, object) => {
      if (item.tiny === entry) {
        object.splice(index, 1);
      }
    });
    return {
      type: DEL_TINY,
      payload: 202, // HTTP_ACCEPTED
    };
  }
  return false;
};
