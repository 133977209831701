import React, {
  useContext, useState, useEffect, useReducer, useRef,
} from 'react';
import shortid from 'shortid';
import config from '../global/config';
import { newTiny, postOptions, newTinyDispatch } from '../redux/tinyActions';
import * as Reducer1 from '../redux/tinyReducer';
import Context from '../utils/context';


export function headOptions() {
  return {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': config.apiHost(),
      Authorization: `Bearer ${sessionStorage.getItem('msal.03197a41-5cfd-4f9b-8001-1d3c197c22b4.idtoken')}`,
    },
  };
}

const EntryForm = () => {
  const [url, setUrl] = useState('');
  const [tiny, setTiny] = useState('');
  const [urlvalid, setUrlValid] = useState(false);
  const [tinyavail, setTinyAvail] = useState(false);
  const [mock, setMock] = useState(true);

  const [stateLocal1, dispatchLocal1] = useReducer(Reducer1.Reducer1,
    Reducer1.initialState);
  const context = useContext(Context);
  const useComponentDidMount = func => useEffect(func, []);
  const useComponentWillMount = (func) => {
    const willMount = useRef(true);
    if (willMount.current) {
      func();
    }
    useComponentDidMount(() => {
      willMount.current = false;
    });
  };
  // useComponentDidMount(() => console.log('didMount'));
  useComponentWillMount(() => {
    const urlhealth = config.healthUri();
    const opts = {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': config.apiHost(),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const request = async () => {
      try {
        const res = await fetch(urlhealth, opts);
        if (res && res.status === 200) {
          Object.assign(config.principal, { mock: false });
          setMock(false);
        }
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
      }
    };
    request();
  });
  // componentWillMount() {
  //   const url = config.healthUri();
  //   const opts = {
  //     method: 'GET',
  //     headers: {
  //       'Access-Control-Allow-Origin': config.apiHost(),
  //       'Content-Type': 'application/json',
  //       Accept: 'application/json',
  //     },
  //   };
  //   const request = async () => {
  //     try {
  //       const res = await fetch(url, opts);
  //       if (res && res.status === 200) {
  //         Object.assign(config.principal, { mock: false });
  //         const s = this.state;
  //         s.mock = false;
  //         this.setState(s);
  //       }
  //     } catch (e) {
  //       console.error(e); // eslint-disable-line no-console
  //     }
  //   };
  //   request();
  // }
  const checkAndAssertAvailChange = (res) => {
    const avail = (res.status === 204);
    if (tinyavail === avail) {
      return;
    }
    setTinyAvail(avail);
  };
  const compileTiny = () => `${tiny}`;
  const checkAvail = (t) => {
    if (!t) {
      return;
    }
    if (config.mock()) {
      setTimeout(() => {
        checkAndAssertAvailChange({ status: 204 });
      }, 0);
      return;
    }
    const request = async () => {
      const testUrl = `${config.apiUri()}/${t}`;
      const res = await fetch(testUrl, headOptions());
      checkAndAssertAvailChange(res);
    };
    request();
  };
  const onUrlChange = (e) => {
    const tinyurl = e.target.value ? shortid.generate().substring(1, 6) : '';
    setUrl(e.target.value);
    setUrlValid(e.target.validity.valid);
    setTiny(tinyurl);
    if (!tinyurl) {
      setTinyAvail(false);
    } else {
      setTimeout(() => {
        checkAvail(tinyurl);
      }, 0);
    }
  };

  const onTinyChange = (e) => {
    const val = e.target.value;
    setTiny(e.target.value);
    if (!e.target.validity.valid) {
      setTinyAvail(false);
    } else {
      setTimeout(() => {
        checkAvail(val);
      }, 0);
    }
  };
  const request1 = async (entry) => {
    const res = await fetch(config.apiUri(), postOptions(entry));
    // console.log(res);
    if (res.status === 201) {
      dispatchLocal1(newTinyDispatch(res));
      context.addGlobalValue();
    }

    return false;
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // const { url } = this.state;
    const entry = {
      url,
      tiny: compileTiny(),
    };
    // const { props } = this;
    if (config.mock()) {
      newTiny(entry);
    } else {
      request1(entry);
    }
    context.decGlobalValue();
    setTiny('');
    setTinyAvail(false);
    setUrl('');
    setUrlValid(false);
    setMock(true);
  };
  const compiledTiny = `${config.fHost()}${compileTiny()}`;
  return (
    <span>
      <div className="luci-long-form-text">
        {stateLocal1.tinyUpdated}
        <p />
        <h3>{`${mock ? '' : ''} To start enter URL below: `}</h3>
      </div>
      <form className="luci-form" onSubmit={onSubmit}>
        <div className="luci-grid">
          <div className="luci-grid__col luci-grid__col-6">
            <input
              type="url"
              name="url"
              id="url"
              className="luci-form__input"
              placeholder="https://target-url.example.com"
              onChange={onUrlChange}
              value={url}
              pattern="https://.*"
            />
          </div>
          <div className="luci-grid__col luci-grid__col-2">
            <input
              id="tiny"
              type="text"
              name="tiny"
              className="luci-form__input"
              placeholder="4-10 Chars"
              onChange={onTinyChange}
              value={tiny}
              minLength={4}
              maxLength={10}
              pattern="[a-zA-Z0-9-_]+"
              disabled={!urlvalid}
            />
          </div>
          <div className="luci-grid__col luci-grid__col-2">
            <input
              id="compiled"
              type="text"
              name="compiled"
              className="luci-form__input"
              value={compiledTiny}
              readOnly
            />
          </div>
          <div className="luci-grid__col luci-grid__col-1">
            <button
              disabled={!(tinyavail && urlvalid)}
              className="luci-button"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </span>
  );
};

export default EntryForm;
