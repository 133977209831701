import { LIST_TINY, NEW_TINY, DEL_TINY } from '../global/actions';

export const initialState = {
  data: {
    list: [],
  },
  tinyUpdated: false,
};

export const Reducer1 = (state = initialState, action) => {
  switch (action.type) {
    case LIST_TINY:
      return {
        data: {
          list: action.payload,
        },
        tinyUpdated: false,
      };
    case NEW_TINY:
      return {
        ...state,
        tinyUpdated: true,
      };
    case DEL_TINY:
      return {
        ...state,
        tinyUpdated: true,
      };
    default:
      return state;
  }
};
