import React, {
  useContext, useEffect, useReducer, useRef,
} from 'react';
import ReactTable from 'react-table';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-table/react-table.css';
import Context from '../utils/context';
import {
  listTiny,
  delTiny,
  listDispatch,
  getOptions,
  delOptions,
  delTinyDispatch,
} from '../redux/tinyActions';
import * as Reducer1 from '../redux/tinyReducer';
import config from '../global/config';

export const timestamp = (seconds) => {
  if (seconds && seconds > 0) {
    const t = new Date(1970, 0, 1);
    t.setSeconds(seconds);
    return t.toLocaleString();
  }
  return '';
};

const Entries = () => {
  /*eslint-disable */
  const context = useContext(Context);
  const contextval = context;
  const [stateLocal1, dispatchLocal1] = useReducer(
    Reducer1.Reducer1,
    Reducer1.initialState,
  );
  const request1 = async () => {
    const res = await fetch(config.apiUri(), getOptions());
    if (res.status === 200) {
      const json = await res.json();
      dispatchLocal1(listDispatch(res, json));
      contextval.decGlobalValue();
    }
    if (res.status === 204) {
      dispatchLocal1(listDispatch(res, []));
    }

    return false;
  };
  if (contextval) {
    const tinyUpdate = contextval.valueGlobalState;
    const tinyUpdateFunc = () => contextval.addGlobalValue();
    useEffect(() => {
      if (context.valueGlobalState) {
        request1();
        tinyUpdateFunc();
      }
    }, [context.valueGlobalState, request1, tinyUpdate, tinyUpdateFunc]);
  }

  /* eslint-enable */
  const useComponentDidMount = func => useEffect(func, []);
  const useComponentWillMount = (func) => {
    const willMount = useRef(true);
    if (willMount.current) {
      func();
    }
    useComponentDidMount(() => {
      willMount.current = false;
    });
  };

  useComponentWillMount(() => {
    const urlval = config.healthUri();
    const opts = {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': config.apiHost(),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    const request = async () => {
      try {
        const res = await fetch(urlval, opts);
        if (res && (res.status === 200 || res.status === 204)) {
          Object.assign(config.principal, { mock: false });
        }
        if (config.mock()) {
          listTiny();
        } else {
          request1();
        }
      } catch (e) {
        // const { props } = this;
        request1();
      }
    };
    request();
  });

  const refresh = () => {
    // const { props } = this;
    setTimeout(() => {
      request1();
    }, 1000);
  };
  const deleteRequest = async (entry) => {
    const res = await fetch(`${config.apiUri()}/${entry}`, delOptions());
    delTinyDispatch(res);
    return false;
  };
  const deleteTiny = (e) => {
    context.decGlobalValue();
    const tiny = e.target.value;
    if (config.mock()) {
      delTiny(tiny);
    } else {
      deleteRequest(tiny);
    }
    context.addGlobalValue();
  };

  const renderActionPanel = cell => (
    <div>
      <CopyToClipboard text={config.fHost() + cell.row.tiny}>
        <button
          type="submit"
          className="luci-button luci-button--secondary luci-button--small"
          value={cell.row.tiny}
        >
          {' '}
          CopyToClipboard
          {' '}
        </button>
      </CopyToClipboard>
      <button
        type="submit"
        className="luci-button luci-button--secondary luci-button--small"
        value={cell.row.tiny}
        onClick={deleteTiny}
      >
        {' '}
        Delete ?
        {' '}
      </button>
    </div>
  );

  const columns = () => {
    const col = [
      {
        Header: () => <div className="luci-form__label">Target Url</div>,
        accessor: 'url',
        Cell: row => (
          <a href={row.value} rel="noreferrer noopener" target="_blank">
            {row.value}
          </a>
        ),
      },
      {
        Header: <div className="luci-form__label">Tiny Url</div>,
        accessor: 'tiny',
        Cell: row => (
          <a
            href={config.host() + row.value}
            rel="noreferrer noopener"
            target="_blank"
            onClick={refresh}
          >
            {config.fHost() + row.value}
          </a>
        ),
      },
      {
        Header: <div className="luci-form__label">#Clicks</div>,
        accessor: 'counter',
        Cell: row => <div>{row.value}</div>,
      },
      {
        Header: <div className="luci-form__label">Last Visited</div>,
        accessor: 'last',
        Cell: row => <div>{row.value}</div>,
      },
      {
        Header: <div className="luci-form__label">Actions</div>,
        accessor: 'tiny',
        Cell: renderActionPanel,
      },
    ];
    return col;
  };

  // const { props } = this;
  const entries = [];
  if (Array.isArray(stateLocal1.data.list)) {
    stateLocal1.data.list.forEach((row) => {
      const r = { ...row };
      r.lastAccess = timestamp(r.last);
      entries.push(r);
    });
  }
  return (
    <div>
      <ReactTable
        data={entries}
        columns={columns()}
        defaultPageSize={15}
        minRows={12}
      />
    </div>
  );
};

export default Entries;
