const ntap = {
  url: 'https://netapp.com/68shj239sj/extremely/long/url/',
  tiny: 'go-ntap',
  last: 0,
  counter: 100,
  expire: -1,
};

const msft = {
  url: 'https://microsoft.com/0zkwheux0xl/extremely/long/url/',
  tiny: '!msft',
  last: 0,
  counter: 1000,
  expire: -1,
};

const goog = {
  url: 'https://google.com/67smasasusa82n/extremely/long/url/',
  tiny: '$goog',
  last: 0,
  counter: 0,
  expire: -1,
};

const appl = {
  url: 'https://apple.com/nshjss8Ssjhd77/extremely/long/url/',
  tiny: 'go--appl',
  last: 0,
  counter: 0,
  expire: -1,
};

class Config {
  zzmockStore = {
    data: {
      list: [ntap, msft, goog, appl],
    },
    tinyUpdated: false,
  };

  fHost = () => 'https://ntap.co/-'

  server = () => `${window.location.protocol}//${window.location.host}`;

  apiHost = () => `${this.server()}`;

  host = () => `${this.server()}/-`

  apiPath = () => '/api/bounce'

  // idPath = () => '/acl/id'

  healthPath = () => '/actuator/health'

  apiUri = () => this.apiHost() + this.apiPath()

  // idUri = () => this.apiHost() + this.idPath()

  healthUri = () => this.apiHost() + this.healthPath()

  principal = {
    mock: true,
    cn: 'Anonymous',
  }

  mock = () => this.principal.mock;

  prefix = () => ['$', '!', '-', 'go'];

  mockStore = () => this.zzmockStore
}

const config = new Config();

export default config;
